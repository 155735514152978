import { useState, forwardRef } from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import PropTypes from 'prop-types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Flex from '@Components/NeosomaUI/Flex';
import CookieImg from '@Components/CookieImg';

import { selectedPatientState, viewerState } from '@Utils/atoms';

// eslint-disable-next-line import/no-cycle
import { Typography } from '@Components/NeosomaUI';
import invalidStudy from '../../assets/invalid_study.png';

const STUDY_STATUS = {
  '-1': '[File Invalid]',
  0: '[Upload Initiated]',
  1: '[Processing Upload]',
  2: '[Analysis Queued]',
  3: '[Starting Segmentation]',
  4: '[Segmentation Complete]',
  5: '[Post Processing]',
  6: '[Analysis Complete]'
};

const ImageLabel = styled.button`
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.palette.white};
  bottom: 0;
  left: 0;
  padding: 8px 11px;
  position: absolute;
  border: 0;
  background: transparent;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: 1px solid transparent;
  border-radius: 3px;
  &:hover {
    outline: ${({ disabled, theme }) =>
      disabled ? 'none' : `1px solid ${theme.palette.shuttlegray}`};
    outline-offset: -6px;
  }
  &:disabled {
    pointer-events: none;
  }
`;

const shouldForwardProp = (prop) => isPropValid(prop);

const Image = styled(Flex, {
  shouldForwardProp
})`
  @media print {
    & img {
      z-index: 10;
    }
  }
  position: relative;
  width: fit-content;
  height: fit-content;
  min-height: 100px;
  min-width: 100%;
  aspect-ratio: 182/218;
  cursor: ${({ disableViewerDblClick, showPointer }) =>
    !disableViewerDblClick || showPointer ? 'pointer' : 'auto'};
  & img {
    height: 218px;
    width: 182px;
    object-fit: contain;
  }
  .hidden {
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  }
  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 25%;
    pointer-events: none;
  }
  &[disabled] {
    cursor: not-allowed;
    & img {
      pointer-events: none;
    }
  }
`;

const ImageOverlay = styled(CookieImg)`
  //TODO: find out why this is needed to be important...
  background-size: cover !important;
  height: 218px;
  width: 182px;
`;

const Button = forwardRef((props, ref) => <ImageLabel {...props} />);
const Overlay = forwardRef((props, ref) => <ImageOverlay {...props} />);

const scanImageTypes = ['T1C', 'FLAIR', 'T1', 'T2'];

export default function ScanImage({
  studyId,
  status = 10,
  hospitalId,
  image,
  title,
  showInteractiveLabel,
  showScanLabel,
  overlay,
  disableViewerDblClick = false,
  handleClick,
  showPointer,
  disabled,
  style
}) {
  const navigate = useNavigate();
  const [selectedImageType, setSelectedImageType] = useState(0);
  const [selectedStudy, setSelectedStudy] =
    useRecoilState(selectedPatientState);

  function getFileName(imageFilename) {
    if (imageFilename === null) return '';
    // We may already have a complete image name
    if (!imageFilename.includes('_preview.png')) return imageFilename;
    // This image string should already have a "ts" value on it
    return imageFilename.replace(
      '.png',
      `_${scanImageTypes[selectedImageType].toLocaleLowerCase()}.png`
    );
  }

  const handleScanTypeClick = () => {
    setSelectedImageType(
      (selectedImageType + 1) % scanImageTypes.length === 0
        ? 0
        : selectedImageType + 1
    );
  };

  const handleOpenClick = () => {
    if (selectedStudy.currentStudy !== studyId) {
      setSelectedStudy({ ...selectedStudy, currentStudy: studyId });
    }
    navigate(`/viewer/${studyId}`, {
      state: { initiator: 'ScanImage', from: window.location.href }
    });
  };

  const handleImgClick = (e) => {
    if (e.detail === 2) {
      !disableViewerDblClick && handleOpenClick();
    } else {
      setTimeout(() => {
        // delay the click event to allow for double click to display viewer.
        // User can avoid seeing the scroll.
        handleClick(e);
      }, 150);
    }
  };

  return (
    <Image
      onClick={image !== '' ? handleImgClick : () => {}}
      title={title}
      disableViewerDblClick={disableViewerDblClick}
      showPointer={showPointer}
      disabled={disabled || image === ''}
    >
      {showInteractiveLabel && image !== '' && (
        <Button
          type="button"
          onClick={handleScanTypeClick}
          title="Click to change sequence preview"
        >
          {scanImageTypes[selectedImageType]}
        </Button>
      )}
      {showScanLabel && (
        <ImageLabel disabled>{scanImageTypes[selectedImageType]}</ImageLabel>
      )}

      {image !== '' && (
        <>
          <CookieImg
            style={style}
            hospitalId={hospitalId}
            tabIndex="-1"
            filename={getFileName(image)}
            options={{ showSpinner: true }}
          />
          <Overlay
            className="overlay"
            hospitalId={hospitalId}
            filename={getFileName(overlay)}
            options={{ showSpinner: false, hideBroken: true }}
          />
        </>
      )}
      {image === '' && (
        <Flex direction="column">
          <Typography
            style={{
              position: 'absolute',
              color: 'white',
              textAlign: 'center',
              width: '100%',
              top: '7px'
            }}
          >
            {STUDY_STATUS[status]}
          </Typography>
          <img src={invalidStudy} alt="" />
        </Flex>
      )}
    </Image>
  );
}

ScanImage.propTypes = {
  studyId: PropTypes.string,
  status: PropTypes.number,
  hospitalId: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  showInteractiveLabel: PropTypes.bool,
  showScanLabel: PropTypes.bool,
  overlay: PropTypes.string,
  handleClick: PropTypes.func,
  disableViewerDblClick: PropTypes.bool,
  showPointer: PropTypes.bool,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

ScanImage.defaultProps = {
  studyId: null,
  status: 10,
  hospitalId: null,
  image: null,
  title: '',
  showInteractiveLabel: false,
  showScanLabel: false,
  overlay: null,
  handleClick: () => {
    /* No Op */
  },
  disableViewerDblClick: false,
  showPointer: false,
  disabled: undefined,
  style: {}
};
