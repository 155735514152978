/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { number, object, func, bool, string } from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import pinPatient from '@Utils/api/pinPatient';
import putPatientUpdate from '@Utils/api/putPatientUpdate';

import { selectedPatientState, currentPatientListState } from '@Utils/atoms';
import Feature from '@Utils/FeatureFlags';
import { getAge, getSlashedDate, maskPII } from '@Utils/common';
import { userPrivs } from '@Utils/auth/userInfoUtils';
import { DROP_DOWN_VARIANTS } from '@Components/NeosomaUI/DropDown';
import { PINNED, UNPINNED, SORT_TYPE } from '@Utils/generalConstants';

import { ReactSVG } from 'react-svg';
import pinOutlineSvg from '../../assets/PIN_OUTLINE.svg';

import Paginator, { DEFAULT_ITEMS_PER_PAGE } from './Paginator';
import TaskListLoader, { Spinner } from './TaskListLoader';
import {
  Container,
  HeaderThing,
  ListContainer,
  StyledTr,
  PinButton,
  ReviewedButton,
  PageContainer,
  WarningIcon,
  DateCell,
  SelectCell,
  SelectHeaderCell,
  ToolTipContainer,
  HideWhileLoading
} from './TaskListStyle';
import { ActionMenu, ActionsMenuList } from './ActionMenuPatientList';
import AddTreatmentModal from './AddTreatementModal';
import ReviewedIcon from './TaskListReviewedIcon';
import TaskListReloadButton from './TaskListReloadButton';
import { useClearCache } from './cacheHelpers';
import { CLEAR_ALL_CACHE } from './helpers';

// *-------------------------*
// |  COMPONENT :: TaskList  |
// *-------------------------*
function TaskList({
  data: { records = [], metadata },
  currentPage,
  doRefresh,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  isLoading,
  filterType
}) {
  const canEditPatient = userPrivs.EDIT_PATIENT;
  const navigate = useNavigate();
  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const [patientList, setPatientList] = useRecoilState(currentPatientListState);
  const [showAddTreatmentModal, setShowAddTreatmentModal] = useState(false);
  const [visaibleTooltip, setVisaibleTooltip] = useState({});
  const invalidateCache = useClearCache();

  const upPatientPinState = (id, newPinState) => {
    const changedPin = { ...selectedPatient[id], pinned: newPinState };
    const newList = [...patientList.records];
    newList[newList.indexOf(newList.find((r) => r.id === id))] = changedPin;
    setPatientList({ ...patientList, records: newList });
    invalidateCache(CLEAR_ALL_CACHE);
    setSelectedPatient({
      ...selectedPatient,
      currentPatient: id,
      [id]: changedPin
    });
  };

  const updatePatientReviewedState = (id, newReviewedState) => {
    const changedReviewed = { ...selectedPatient[id], ...newReviewedState };
    const newList = [...patientList.records];
    newList[newList.indexOf(newList.find((r) => r.id === id))] =
      changedReviewed;
    setPatientList({ ...patientList, records: newList });
    invalidateCache(CLEAR_ALL_CACHE);
    setSelectedPatient({
      ...selectedPatient,
      currentPatient: id,
      [id]: changedReviewed
    });
  };

  const handlePinClick = useMutation({
    mutationFn: ({ id, newPinState }) => {
      upPatientPinState(id, newPinState);
      return pinPatient(id, newPinState);
    },
    onError: (error, { id, newPinState }) => {
      upPatientPinState(id, newPinState ? UNPINNED : PINNED);
    }
  });

  const handleReviewChange = useMutation({
    mutationFn: ({ record }) => {
      const isReviewed =
        record.sort_type === SORT_TYPE.LAST_STUDY_WAS_REVIEWED_4;
      const payload = isReviewed
        ? { next_scan_date: null, sort_type: SORT_TYPE.NEW_PATIENT_NO_SCANS_3 }
        : {
            next_scan_date: new Date('12/31/2099').toISOString(),
            sort_type: SORT_TYPE.LAST_STUDY_WAS_REVIEWED_4
          };
      payload.event = isReviewed
        ? 'Unreviewed Image'
        : 'Latest study was reviewed';
      updatePatientReviewedState(record.id, payload);
      return putPatientUpdate({
        patientId: record.id,
        changesPayload: payload
      });
    },
    onError: (error, { record }) => {
      const orginalReviewedState = {
        sort_type: record.sort_type,
        next_scan_date: record.next_scan_date,
        event: record.event
      };
      upPatientPinState(record.id, orginalReviewedState);
    }
  });

  const handleClick = (event, id) => {
    if (event.detail === 2) {
      navigate(`/patients/${id}`, { state: { from: window.location.href } });
    } else if (selectedPatient.currentPatient !== id) {
      setSelectedPatient({
        ...selectedPatient,
        currentPatient: id,
        currentStudy: undefined,
        [id]: records.find((rec) => rec.id === id)
      });
    }
  };

  const handleActionComplete = (success) => {
    if (success) {
      invalidateCache(CLEAR_ALL_CACHE);
      selectedPatient.refreshPatientList();
    }
  };

  // Patient checkbox logic
  const [selectionList, setSelection] = useState({});
  const handleChecked = (e, id) => {
    const nextList = { ...selectionList };
    if (selectionList[id]) {
      delete nextList[id];
    } else {
      nextList[id] = true;
    }
    setSelection(nextList);
  };

  const handleAddTreatmentClicked = () => {
    setShowAddTreatmentModal(true);
  };

  const handlePinIconClick = (patientRows, thisRecord) => {
    const { id: patientId, pinned } = thisRecord;
    setSelectedPatient({
      ...selectedPatient,
      currentPatient: patientId,
      currentStudy: undefined,
      [patientId]: patientRows.find((record) => record.id === patientId)
    });
    handlePinClick.mutate({
      id: patientId,
      newPinState: !pinned ? PINNED : UNPINNED
    });
  };

  const handleReviewIconClick = (patientRows, thisRecord) => {
    const { id: patientId } = thisRecord;
    if (selectedPatient.currentPatient !== patientId) {
      setSelectedPatient({
        ...selectedPatient,
        currentPatient: patientId,
        currentStudy: undefined,
        [patientId]: patientRows.find((record) => record.id === patientId)
      });
    }
    handleReviewChange.mutate({ record: thisRecord });
  };
  const getPinnedPatientLabel = (pinned, firstname, lastname) => {
    const name = `${maskPII(firstname)} ${maskPII(lastname)}`;
    if (canEditPatient) {
      return `${pinned ? 'Unpin' : 'Pin'} ${name}`;
    }
    return `${pinned ? 'Pinned' : 'Unpinned'}`;
  };

  const getReviewedPatientLabel = (sortType) => {
    if (canEditPatient) {
      return `${
        sortType === SORT_TYPE.LAST_STUDY_WAS_REVIEWED_4
          ? 'Mark as Unreviewed'
          : 'Mark as Reviewed'
      }`;
    }
    return `${
      sortType === SORT_TYPE.LAST_STUDY_WAS_REVIEWED_4
        ? 'Reviewed'
        : 'Unreviewed'
    }`;
  };

  const getPatientDiagnosis = (record) => {
    if (record.diagnosis) {
      return record.diagnosis;
    }
    if (record.last_study_type === 3) {
      return '[Mets]';
    }
    if ([1, 2].includes(record.last_study_type)) {
      return '[Glioma]';
    }
    return '';
  };

  return (
    <Container direction="column" data-test="task-list" grow="1">
      <AddTreatmentModal
        visable={showAddTreatmentModal}
        setVisable={setShowAddTreatmentModal}
      />
      <HeaderThing>
        <TaskListReloadButton isLoading={isLoading} onClick={doRefresh} />
        <Feature name="non-mvp-feature">
          <ActionMenu disabled={isLoading}>
            <ActionsMenuList
              selectedPatientList={Object.keys(selectionList)}
              onComplete={handleActionComplete}
            />
          </ActionMenu>
        </Feature>
      </HeaderThing>
      {isLoading && records.length === 0 && (
        <TaskListLoader loading={isLoading} rows={rowsPerPage} />
      )}
      <HideWhileLoading isLoading={isLoading && records.length === 0}>
        {isLoading && (
          <div className="loading">
            <Spinner
              style={{
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                paddingBottom: '100px' // Offset for pagginator
              }}
            >
              <span className="loader" />
            </Spinner>
          </div>
        )}
        <ListContainer>
          <table onBlur={() => setVisaibleTooltip({})}>
            <thead>
              <tr>
                <Feature name="non-mvp-feature">
                  <SelectHeaderCell>
                    <label htmlFor="selectAll" title="Select All">
                      <span className="sr-only-no-DOM">
                        Select all patients
                      </span>
                      <input
                        id="selectAll"
                        type="checkbox"
                        style={{ visibility: 'hidden' }}
                      />
                    </label>
                  </SelectHeaderCell>
                </Feature>
                <th>Patient Name</th>
                <th>Age/Sex</th>
                <th title="Shows [Module] if not confirmed">Diagnosis</th>
                <th style={{ width: '200px' }}>Event</th>
                <th>Date</th>
                <th className="sr-only" style={{ width: '115px' }}>
                  Pin
                </th>
              </tr>
            </thead>
            <tbody>
              {records &&
                records.map((rec) => (
                  <StyledTr
                    key={rec.id}
                    activeClassName="any"
                    selected={selectedPatient.currentPatient === rec.id}
                    tabIndex="0"
                  >
                    <Feature name="non-mvp-feature">
                      <SelectCell>
                        <input
                          type="checkbox"
                          onClick={(e) => handleChecked(e, rec.id)}
                        />
                      </SelectCell>
                    </Feature>
                    <td
                      data-test="name"
                      onClick={(e) => {
                        handleClick(e, rec.id);
                      }}
                    >
                      {maskPII(
                        `${rec.first_name || ''} ${rec.last_name || ''}`
                      )}
                    </td>
                    <td
                      data-test="age_sex"
                      onClick={(e) => {
                        handleClick(e, rec.id);
                      }}
                    >
                      {rec.dob &&
                        `${getAge(rec.dob, rec.age_type) || '--'} YO ${
                          typeof rec.sex === 'string' && rec.sex[0]
                            ? rec.sex[0]
                            : ''
                        }`}
                    </td>
                    <td
                      data-test="diagnosis"
                      title="Shows [Module] if not confirmed"
                      onClick={(e) => {
                        handleClick(e, rec.id);
                      }}
                    >
                      {getPatientDiagnosis(rec)}
                    </td>
                    <td
                      data-test="event"
                      onClick={(e) => {
                        handleClick(e, rec.id);
                      }}
                    >
                      {rec.event}
                    </td>
                    <DateCell
                      data-test="date"
                      onClick={(e) => {
                        handleClick(e, rec.id);
                      }}
                    >
                      <Feature name="non-mvp-feature">
                        {selectedPatient.currentPatient !== rec.id && (
                          <span className="remove-on-hover">
                            {getSlashedDate(rec.date)}
                          </span>
                        )}
                      </Feature>
                      <Feature name="non-mvp-feature" isOff>
                        <span>{getSlashedDate(rec.date)}</span>
                      </Feature>
                      <Feature name="non-mvp-feature">
                        <div className="add-on-hover-keep-on-selected">
                          <ActionMenu variant={DROP_DOWN_VARIANTS.SLIM}>
                            <ActionsMenuList
                              selectedPatientList={[rec.id]}
                              onComplete={handleActionComplete}
                              onAddTreatment={handleAddTreatmentClicked}
                              filterType={filterType}
                              doPin={() =>
                                handlePinClick.mutate({
                                  id: rec.id,
                                  newPinState: !rec.pinned ? PINNED : UNPINNED
                                })
                              }
                            />
                          </ActionMenu>
                        </div>
                      </Feature>
                    </DateCell>
                    <td
                      data-test="pin"
                      onMouseLeave={() => setVisaibleTooltip({})}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '22px',
                        paddingRight: '29px'
                      }}
                    >
                      <div className="show-on-hover" pinned={`${!!rec.pinned}`}>
                        {selectedPatient.currentPatient === rec.id &&
                          handlePinClick.error && (
                            <WarningIcon title="Pinning was not saved, try again">
                              !
                            </WarningIcon>
                          )}
                        <Popover
                          isOpen={visaibleTooltip[`${rec.id}-pin`]}
                          positions="bottom"
                          padding={3}
                          content={
                            <ToolTipContainer>
                              {getPinnedPatientLabel(
                                rec.pinned,
                                rec.first_name,
                                rec.last_name
                              )}
                            </ToolTipContainer>
                          }
                          align="center"
                        >
                          <PinButton
                            onMouseEnter={() =>
                              setVisaibleTooltip({
                                [`${rec.id}-pin`]: true
                              })
                            }
                            onMouseLeave={() =>
                              setVisaibleTooltip({
                                [`${rec.id}-pin`]: false
                              })
                            }
                            pinned={!!rec.pinned}
                            aria-label={getPinnedPatientLabel(
                              rec.pinned,
                              rec.first_name,
                              rec.last_name
                            )}
                            onClick={() =>
                              canEditPatient && handlePinIconClick(records, rec)
                            }
                            canEditPatient={canEditPatient}
                          >
                            <ReactSVG src={pinOutlineSvg} />
                          </PinButton>
                        </Popover>
                      </div>
                      <Feature name="non-mvp-feature" isOff>
                        <div className="show-on-hover">
                          {selectedPatient.currentPatient === rec.id &&
                            handleReviewChange.error && (
                              <WarningIcon title="Review change was not saved, try again">
                                !
                              </WarningIcon>
                            )}
                          <Popover
                            isOpen={visaibleTooltip[`${rec.id}-review`]}
                            positions="bottom"
                            padding={3}
                            content={
                              <ToolTipContainer>
                                {getReviewedPatientLabel(rec.sort_type)}
                              </ToolTipContainer>
                            }
                            align="center"
                          >
                            <ReviewedButton
                              onMouseEnter={() =>
                                setVisaibleTooltip({
                                  [`${rec.id}-review`]: true
                                })
                              }
                              onMouseLeave={() =>
                                setVisaibleTooltip({
                                  [`${rec.id}-review`]: false
                                })
                              }
                              onBlur={() => {
                                setVisaibleTooltip({
                                  [`${rec.id}-review`]: false
                                });
                              }}
                              onClick={() =>
                                canEditPatient &&
                                handleReviewIconClick(records, rec)
                              }
                            >
                              <ReviewedIcon
                                isHovered={visaibleTooltip[`${rec.id}-review`]}
                                record={rec}
                                canEditPatient={canEditPatient}
                              />
                            </ReviewedButton>
                          </Popover>
                        </div>
                      </Feature>
                    </td>
                  </StyledTr>
                ))}
            </tbody>
          </table>
        </ListContainer>
        <PageContainer>
          {metadata && (
            <Paginator
              count={records?.length}
              totalItems={metadata?.total_count}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </PageContainer>
      </HideWhileLoading>
    </Container>
  );
}

TaskList.defaultProps = {
  data: {},
  currentPage: 0,
  doRefresh: () => {},
  setCurrentPage: () => {},
  rowsPerPage: DEFAULT_ITEMS_PER_PAGE,
  setRowsPerPage: () => {},
  isLoading: false,
  filterType: ''
};

TaskList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object,
  currentPage: number,
  doRefresh: func,
  setCurrentPage: func,
  rowsPerPage: number,
  setRowsPerPage: func,
  isLoading: bool,
  filterType: string
};

export default TaskList;
