/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { selectedPatientState } from '@Utils/atoms';
import { Flex, Margin, SelectMenu, Typography } from '@Components/NeosomaUI';
import {
  getDateWithSpaces,
  getScanTimestamp,
  generateScanImageFilename
} from '@Utils/common';
import { getSegmentationValues, addPriorProprties } from '@Utils/imaging';
import EmpiricalResults from './EmpiricalResults';
import ImageCard from './ImageCard';

const TabTitle = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.platinum};
  padding-bottom: 8px;
  margin-bottom: 23px;
`;

const Container = styled(Flex)`
  margin-bottom: 23px;
`;

const LeftSide = styled.div`
  width: 80%;
  border-right: 1px solid ${({ theme }) => theme.palette.platinum};
  padding-right: 22px;
  flex-grow: 1;
`;

const RightSide = styled.div`
  width: fit-content;
  padding-left: 22px;
  min-width: 211px;
  flex-grow: 0;
`;

const StyledSelectMenu = styled(SelectMenu)`
  min-width: 100%;
  padding-right: 12px;
`;

const EmptyImageState = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.palette.submarine};
  border-radius: 2px 2px 0 0;
  background-color: ${({ theme }) => theme.palette.platinum};
  max-width: 152px;
  height: 200px;
  padding: 16px;
`;

function EmptyCard() {
  return (
    <EmptyImageState alignItems="center">
      <Typography align="center" bold>
        No Scans available
      </Typography>
    </EmptyImageState>
  );
}

export default function Imaging({ patientId }) {
  const pData = useRecoilValue(selectedPatientState) || {};
  const patientData = pData[patientId] || {};

  const [filteredImage, setFilteredImage] = useState(null);

  const filterImageList = (opt) => {
    if (opt.value === 'all') {
      setFilteredImage(null);
    } else {
      setFilteredImage(opt.value);
    }
  };
  const hasImages = patientData?.studies?.length > 0;

  // Parse studies into data needed for study image cards.
  const getImageCardData = () => {
    const { baseline_study_id, nadir_study_id, latest_study_id } = patientData;
    const studyCategories = {
      latest: patientData.studies.find((s) => s.id === latest_study_id),
      baseline: patientData.studies.find((s) => s.id === baseline_study_id),
      nadir: patientData.studies.find((s) => s.id === nadir_study_id)
    };

    if (latest_study_id === nadir_study_id) {
      delete studyCategories.nadir;
    }
    if (latest_study_id === baseline_study_id) {
      delete studyCategories.baseline;
    }

    if (baseline_study_id === nadir_study_id) {
      delete studyCategories.nadir;
    }

    // Bust the cache for any "edited" images after any edit
    const ts = getScanTimestamp();

    const data = Object.keys(studyCategories).map((category) => {
      const {
        id: study_id,
        study_type,
        status,
        preview_image,
        study_date
      } = studyCategories[category] || {};
      if (study_id === undefined) return undefined;

      const categoryLabel = (() => {
        if (study_id === latest_study_id) {
          let text = 'Latest Scan';
          if (
            latest_study_id === nadir_study_id &&
            latest_study_id === baseline_study_id
          ) {
            text = 'Latest Scan (Baseline, Nadir)';
          } else if (latest_study_id === nadir_study_id) {
            text = 'Latest Scan (Nadir)';
          } else if (latest_study_id === baseline_study_id) {
            text = 'Latest Scan (Baseline)';
          }
          return text;
        }
        if (study_id === baseline_study_id) {
          return `Baseline Scan ${
            baseline_study_id === nadir_study_id ? '(Nadir)' : ''
          }`;
        }
        if (study_id === nadir_study_id) {
          return 'Nadir Scan';
        }
        return undefined;
      })();

      return {
        study_id,
        status,
        study_type,
        key: `${study_id}-${category}`,
        displayText: categoryLabel,
        type: category,
        filename: generateScanImageFilename(
          patientId,
          patientData.hospital_id,
          study_id
        ),
        overlayFilename: (preview_image || '').replace(
          '.png',
          `_seg.png?ts${ts}`
        ),
        date: study_date
      };
    });
    return data;
  };

  const getScanList = () => {
    const { baseline_study_id, nadir_study_id, latest_study_id } = patientData;

    // Bust the cache for any "edited" images after any edit
    const ts = getScanTimestamp();
    const data = patientData.studies
      .map((study) => {
        const {
          id: study_id,
          status,
          preview_image,
          study_date,
          study_type
        } = study || {};
        if (study_id === undefined) return undefined;

        const isSpecial = (() => {
          if (study_id === latest_study_id) {
            let text = 'Latest Scan';
            if (
              latest_study_id === nadir_study_id &&
              latest_study_id === baseline_study_id
            ) {
              text = 'Latest Scan (Baseline/Nadir)';
            } else if (latest_study_id === nadir_study_id) {
              text = 'Latest Scan (Nadir)';
            } else if (latest_study_id === baseline_study_id) {
              text = 'Latest Scan (Baseline)';
            }
            return text;
          }
          if (study_id === baseline_study_id) {
            return `Baseline Scan ${
              baseline_study_id === nadir_study_id ? '(Nadir)' : ''
            }`;
          }
          if (study_id === nadir_study_id) {
            return 'Nadir Scan';
          }
          return undefined;
        })();
        return {
          study_id,
          status,
          study_type,
          key: study_id,
          type: study,
          filename:
            status === 10
              ? generateScanImageFilename(
                  patientId,
                  patientData.hospital_id,
                  study_id
                )
              : '',
          overlayFilename:
            status === 10
              ? (preview_image || '').replace('.png', `_seg.png?ts${ts}`)
              : '',
          date: study_date,
          isSpecial
        };
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    return data;
  };

  const studyDates = (patientData.studies || []).map((s) => ({
    id: s.id,
    date: new Date(s.study_date).getTime()
  }));

  // Only show status = 10 in the Charts
  let imageData = (patientData.studies || [])
    .filter((s) => s.status === 10)
    .map((s) => getSegmentationValues(s))
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  if (imageData.length === 0) {
    return (
      <Typography variant="h1">No studies found for this patient</Typography>
    );
  }
  imageData = addPriorProprties(imageData);

  return (
    <>
      <TabTitle>
        <Typography variant="h1" bold>
          Imaging
        </Typography>
      </TabTitle>
      <Flex>
        <LeftSide>
          {hasImages ? (
            <>
              <Container gap="34px">
                {getImageCardData().map((study) => {
                  if (study === undefined) {
                    return null;
                  }
                  return (
                    <ImageCard
                      key={study.key}
                      studyId={study.study_id}
                      status={study.status}
                      hospitalId={patientData.hospital_id}
                      title={study.displayText}
                      subTitle={getDateWithSpaces(study.date)}
                      image={study.filename}
                      showInteractiveLabel={study.study_type !== 3}
                      showScanLabel={study.study_type === 3}
                      overlay={study.overlayFilename}
                      isDupe={study.isDupe}
                      dupeText={study.dupeText}
                      handleClick={() => {
                        document
                          .getElementById(`${study.study_id}-right`)
                          ?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'nearest'
                          });
                      }}
                    />
                  );
                })}
              </Container>
              <TabTitle>
                <Typography variant="h2" bold>
                  Empirical Results
                </Typography>
              </TabTitle>
              <EmpiricalResults
                data={imageData.sort(
                  (a, b) => new Date(a.date) - new Date(b.date)
                )}
              />
            </>
          ) : (
            <EmptyCard />
          )}
        </LeftSide>
        <RightSide>
          {hasImages ? (
            <>
              <Flex alignItems="center" gap="8px">
                <StyledSelectMenu
                  options={[
                    {
                      label: 'All',
                      value: 'all'
                    },
                    ...studyDates
                      .sort((a, b) => new Date(b.date) - new Date(a.date)) // sort by date Newest to oldest
                      .map((result) => ({
                        label: getDateWithSpaces(result.date),
                        value: result.id
                      }))
                  ]}
                  onChange={filterImageList}
                  placeholder="All Scans"
                />
              </Flex>
              <Margin bottom="20px" />
              <Flex
                direction="column"
                gap="15px"
                style={{
                  maxHeight: '785px',
                  overflow: 'auto',
                  paddingRight: '5px',
                  width: '205px'
                }}
              >
                {getScanList()
                  .sort((a, b) => new Date(b.date) - new Date(a.date)) // sort by date Newest to oldest
                  .filter(
                    // filter by selected image
                    (imageResult) =>
                      imageResult.study_id === filteredImage ||
                      filteredImage === null
                  )
                  .map((imageResult) => (
                    <ImageCard
                      id={`${imageResult?.study_id}-right`}
                      studyId={imageResult?.study_id}
                      status={imageResult?.status}
                      hospitalId={patientData.hospital_id}
                      title={getDateWithSpaces(imageResult?.date)}
                      subTitle={imageResult?.isSpecial}
                      image={imageResult?.filename}
                      showInteractiveLabel={imageResult?.study_type !== 3}
                      showScanLabel={imageResult?.study_type === 3}
                      overlay={imageResult?.overlayFilename}
                      key={`${imageResult?.study_id}-right`}
                    />
                  ))}
              </Flex>
            </>
          ) : (
            <EmptyCard />
          )}
        </RightSide>
      </Flex>
    </>
  );
}

Imaging.propTypes = {
  patientId: PropTypes.string.isRequired
};
