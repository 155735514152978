/* eslint-disable react/prop-types */
import { useState } from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

function CustomizedTick(props) {
  const { x, y, payload, tickFormatter, angle, dx, dy } = props;
  const [year, month, day] = tickFormatter(payload.value).split('-');
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={23}
        y={23}
        dy={16}
        fill="#666"
        transform={`rotate(${angle}, ${dx},${dy})`}
      >
        <tspan textAnchor="middle" x="4">
          {`${day}-${month}`}
        </tspan>
        <tspan textAnchor="middle" x="4" dy="11">
          {`${year}`}
        </tspan>
      </text>
    </g>
  );
}

export const dateFormatter = (date) => {
  if (date === 0) {
    return '';
  }

  return new Date(date)
    .toUTCString()
    .substring(5, 16)
    .split(' ')
    .reverse()
    .join('-');
};

export const getTicksArray = (array, prop, numTicks) => {
  const ticks = [0];
  const tickIncrement = getMaxYAxisValue(array, prop, numTicks) / numTicks;
  for (let i = 1; i <= numTicks; i += 1) {
    ticks.push(ticks[ticks.length - 1] + tickIncrement);
  }
  return ticks;
};

export const getMaxYAxisValue = (array, prop, numTicks) => {
  const maxVal = Math.max(...array.map((x) => x[prop]));
  const ticks = [5, 10, 20, 25, 40, 50, 100, 200, 250, 500, 1000, 2500, 5000];
  return Math.min(...ticks.filter((x) => x >= maxVal / numTicks)) * 5;
};

export default function EmpiricalResultsGraph({ data, extraClass }) {
  const [strokeWidth, setStrokeWidth] = useState({
    enhancing: 1,
    flair: 1,
    necrosis: 1,
    whole: 1
  });

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    setStrokeWidth({
      ...{
        enhancing: 1,
        flair: 1,
        necrosis: 1,
        whole: 1
      },
      [dataKey]: 3
    });
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;
    setStrokeWidth({ ...strokeWidth, [dataKey]: 1 });
  };

  const axisType = localStorage.axisScale === 'off' ? 'string' : 'number';
  const axisScale = localStorage.axisScale === 'off' ? 'none' : 'time';

  return (
    <ResponsiveContainer
      width="99.9%"
      height="99.8%"
      className={`${extraClass ? ` ${extraClass}` : ''}`}
    >
      <AreaChart
        height={300}
        width={600}
        data={data}
        margin={{
          top: 5,
          right: 60,
          left: 20,
          bottom: 32
        }}
      >
        <Legend
          verticalAlign="top"
          height={36}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <XAxis
          dataKey="date"
          interval="preserveStartEnd"
          type={axisType}
          scale={axisScale}
          angle={45}
          dx={25}
          dy={30}
          domain={[data[0].date, data[data.length - 1].date]}
          tickFormatter={dateFormatter}
          tick={CustomizedTick}
        />
        <YAxis
          interval="0"
          ticks={getTicksArray(data, 'whole', 5)}
          domain={[0, getMaxYAxisValue(data, 'whole', 5)]}
          type="number"
          scale="linear"
          unit=" cm³"
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          wrapperStyle={{ zIndex: '1000' }}
          labelFormatter={dateFormatter}
          formatter={(value, name, obj) => {
            // Access obj.payload for full data set
            const value0 = (obj.payload.prior || {})[obj.dataKey] || 0;
            let display = `${value.toFixed(2)} cm³`;
            if (value === 0) {
              display += ' (Not Detected)';
            } else if (value0 === 0) {
              display += ' (Initial Measurement)';
            } else {
              const delta = Math.min(999.99, ((value - value0) / value0) * 100);
              if (delta === 0) {
                display += ' (No Change)';
              } else {
                const pos = delta > 0 ? '↑' : '↓';
                display += ` (${pos}${Math.abs(delta).toFixed(2)}%`;
                display += ` from ${value0.toFixed(2)} cm³)`;
              }
            }
            return display;
          }}
        />
        <Area
          animationDuration={1000}
          name="Enhancing"
          type="monotone"
          dataKey="enhancing"
          dot={{ r: 3 }}
          activeDot={{ r: 5 }}
          stroke="#FF0000"
          strokeWidth={strokeWidth.enhancing}
          fillOpacity={0.2}
        />
        {data.length > 0 &&
          data[0].date > 0 &&
          data.slice(-1)[0].study_type !== 3 && (
            <Area
              animationDuration={1000}
              name="FLAIR"
              type="monotone"
              dataKey="flair"
              dot={{ r: 3 }}
              activeDot={{ r: 5 }}
              stroke="#0000FF"
              strokeWidth={strokeWidth.flair}
              fillOpacity={0.2}
            />
          )}
        {data.length > 0 &&
          data[0].date > 0 &&
          data.slice(-1)[0].study_type !== 2 && (
            <Area
              animationDuration={1000}
              name="Necrosis / Cavity"
              type="monotone"
              dataKey="necrosis"
              dot={{ r: 3 }}
              activeDot={{ r: 5 }}
              stroke="#E1A100"
              strokeWidth={strokeWidth.necrosis}
              fill="#FFBB00"
              fillOpacity={0.3}
            />
          )}
        <Area
          animationDuration={1000}
          name="Whole Tumor"
          type="monotone"
          dataKey="whole"
          dot={{ r: 3 }}
          activeDot={{ r: 5 }}
          stroke="#000000"
          strokeWidth={strokeWidth.whole}
          fill="#777777"
          fillOpacity={0.1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
